import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typography",
      "style": {
        "position": "relative"
      }
    }}>{`Typography`}</h1>
    <p>{`Typography is used to define a hierarchy between content groups and guide the user through the interface. The DS typography is organized into 5 levels of headings, body, buttons, and links, and has color and weights (regular and bold) varied.`}</p>
    <h2 {...{
      "id": "family-font",
      "style": {
        "position": "relative"
      }
    }}>{`Family font`}</h2>
    <p>{`The typographic family is `}<a parentName="p" {...{
        "href": "https://www.ibm.com/plex/"
      }}>{`IBM Plex Sans`}</a>{` for its versatility and readability, as well as being an open-sourced typeface.`}</p>
    <h2 {...{
      "id": "type-scales",
      "style": {
        "position": "relative"
      }
    }}>{`Type scales`}</h2>
    <table>
  <tbody>
    <tr>
      <td>0.8125 rem</td>
      <td>13 px</td>
      <td>
        <h5 style={{
              margin: 0
            }}>H5 - Don't design for designers, design for people</h5>
      </td>
    </tr>
    <tr>
      <td>0.875 rem</td>
      <td>14 px</td>
      <td>
        <h4 style={{
              margin: 0
            }}>H4 - Don't design for designers, design for people</h4>
      </td>
    </tr>
    <tr>
      <td>1 rem</td>
      <td>16 px</td>
      <td>
        <h3 style={{
              margin: 0
            }}>H3 - Don't design for designers, design for people</h3>
      </td>
    </tr>
    <tr>
      <td>1.25 rem</td>
      <td>20 px</td>
      <td>
        <h2 style={{
              margin: 0
            }}>H2 - Don't design for designers, design for people</h2>
      </td>
    </tr>
    <tr>
      <td>1.5 rem</td>
      <td>24 px</td>
      <td>
        <h1 style={{
              margin: 0
            }}>H1 - Don't design for designers, design for people</h1>
      </td>
    </tr>
  </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      